import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { authFormState } from "../../Atoms/auth/AuthFormState";
import { useDarkTheme } from "../../contexts/DarkTheme";
import LoginCard from "./LoginCard";
import ResetPassword from "./ResetPassword";
import CreateCard from "./CreateCard";
import Loader from "../Loader";
import { usePathname } from "next/navigation";

type AuthCardProps = {
  toast?: any;
};

const AuthCard: React.FC<AuthCardProps> = (props: AuthCardProps) => {
  const [formState, setFormState] = useRecoilState(authFormState);
  const [isMount, setMount] = useState(false);
  const pathname = usePathname();
  useEffect(() => {
    setMount(true);
  }, []);
  console.log("errrishere", 1);
  if (!isMount) return <Loader />;
  console.log("pathname", pathname);
  return (
    <>
      {formState.view == "login" && <LoginCard />}
      {formState.view == "create" && <CreateCard />}
      {formState.view == "resetPassword" && (
        <ResetPassword toast={props.toast} />
      )}
    </>
  );
};
export default AuthCard;
