import axios from "axios";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authFormState } from "../../Atoms/auth/AuthFormState";
import { userState } from "../../Atoms/auth/UserAuth";
import { API_URL } from "../../config";
// import language from '../../helpers/language';
import { useToast } from "@/components/ui/use-toast";
import { Eye, EyeOff, Loader2 } from "lucide-react";
import { NextPage } from "next";
import { signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";
const LoginSocialGoogle = dynamic(() => import("./social-login/GoogleLogin"), {
  ssr: false,
});
import Disclaimer from "../Desclaimer";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import dynamic from "next/dynamic";
import GoogleAnalytics from "../GA";
import { useSubscription } from "@/contexts/SubscriptionContext";
interface IFormValues {
  email: string;
  password: string;
}

const LoginCard: NextPage = (props: any) => {
  const { toast } = useToast();
  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation("common");
  const [userStatea, setUserState] = useRecoilState(userState);
  const setFormState = useSetRecoilState(authFormState);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setSubscriptionData, setMetaData, getUserType } = useSubscription();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>();
  const togglePasswordVisibility = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const onSubmit = async (formData: any) => {
    setLoading(true);
    let url = new URL("login", API_URL);
    const formD = new FormData();
    formD.append("email", (formData.email as string) || "");
    formD.append("password", (formData.password as string) || "");

    // url.searchParams.append("email", (formData.email as string) || "");
    // url.searchParams.append("password", (formData.password as string) || "");
    axios
      .post(url.toString(), formD, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.statusText == "OK") {
          setLoading(true);
          setSubscriptionData(JSON.parse(res?.data?.subscription || "null"));
          setMetaData(res?.data?.user?.meta_data || null);
          getUserType(res.data);
          localStorage.setItem("user-info", JSON.stringify(res.data));
          localStorage.setItem("userAuthenticated", "true");
          router.query.locale = res.data.preference.language || "en";
          if (res.data?.subscription && res.data?.subscription == "inactive") {
            return router.push(
              `/subscription?locale=${router.query.locale || "en"}`
            );
          }
          if (router.query.redirect)
            return router.push(router.query.redirect as string);
          console.log("errrishere");

          // return router.push("/build");
          return router.push(
            `/welcome-tour?locale=${router.query.locale || "en"}`
          );
        }
      })
      .catch((e) => {
        console.error("error ", e.message);
        setLoading(false);
        setError(e.response.data.detail || e.response.data.error);
        toast({
          title: e.response.data.detail || e.response.data.error,
          variant: "destructive",
        });
        return;
        // throw new Error(e.message);
      });
  };
  const setAuthHeader = (token: string) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user-info")!);
    const userAuthenticated = localStorage.getItem("userAuthenticated");
    if (userInfo?.user_id && userAuthenticated === "true") {
      setLoading(true);

      // check if session is valid
      var url = new URL("verify_session", API_URL);
      axios
        .post(url.toString(), {}, setAuthHeader(userInfo?.token))
        .then((res) => {
          if (res.statusText == "OK") {
            router.query.locale = userInfo?.preference.language || "en";
            if (router.query.redirect) {
              router.push(router?.query?.redirect?.toString());
              return () => {};
            }
            // setLoading(false);
            //if (userInfo?.login_flow_new_user == "1") {
            // router.push('/welcome');
            // return false;
            //router.push(`/on-boarding`);
            //  router.push(`/explore?locale=${userInfo?.language}`);
            // } else {
            // router.push(
            //   `/build?locale=${userInfo?.language || router.query.locale || "en"
            //   }`
            // );
            router.push(
              `/welcome-tour?locale=${
                userInfo?.language || router.query.locale || "en"
              }`
            );
          }
        })
        .catch((e) => {
          localStorage.clear();
          signOut({ redirect: false });
          console.log("errrishere");
          setLoading(false);
          return;
        })
        .finally(() => {
          setLoading(false);
          console.log("errrishere");
        });
      // }
    }
  }, [session, router]);

  if (session) {
    //router.push('/build')
    // return (
    //   <div className="">
    //     Welcome user<br />
    //     <button onClick={() => signOut()}>Sign out</button>
    //   </div>
    // );
  }

  return (
    <>
      <GoogleAnalytics GA_TRACKING_ID={"G-NRPKERR6KT"} />
      <div className="max-w-[500px] mb-5">
        <form
          method="post"
          autoComplete="false"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-4 loginForm"
        >
          <Input
            {...register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("components.loginCard.error.validEmail"),
              },
            })}
            type="text"
            autoComplete="off"
            onKeyUp={() => setError("")}
            placeholder={t("components.loginCard.emailAddress")}
            name="email"
          />
          {errors?.email && (
            <div className="danger text-left">
              {errors.email?.type === "required" && (
                <p className="text-destructive text-sm">
                  {t("components.loginCard.error.emailRequired")}
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p className="text-destructive text-sm">
                  {errors.email?.message}
                </p>
              )}
            </div>
          )}
          <div className="relative">
            <Input
              {...register("password", { required: true })}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              placeholder={t("components.loginCard.password")}
              onKeyUp={() => setError("")}
              name="password"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 cursor-pointer">
              {showPassword ? (
                <EyeOff
                  className="h-5 w-5"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <Eye className="h-5 w-5" onClick={togglePasswordVisibility} />
              )}
            </div>
          </div>

          {errors?.password && (
            <div className="danger text-left">
              {errors.password?.type === "required" && (
                <p className="text-destructive text-sm">
                  {t("components.loginCard.error.passwordRequired")}
                </p>
              )}
            </div>
          )}
          {error && <span className="text-destructive text-sm">{error}</span>}

          <div className="text-center">
            <Button type="submit">
              {t("components.loginCard.signIn")}

              {loading ? <Loader2 className="ml-2 h-5 w-5 animate-spin" /> : ""}
            </Button>
          </div>
        </form>
      </div>
      <div className="flex flex-col !mt-2">
        {/* <div className="flex flex-row justify-center items-center text-newGreen  text-xs">
          <div className="w-[30%] border-t border-primary"></div>
          <p className="mx-2">{t("components.loginCard.or")}</p>
          <div className="w-[30%] border-t border-primary"></div>
        </div> */}
        {
          // <p className="">{t("components.loginCard.loginWith")}</p>
        }
        {/* <div className="flex flex-row justify-center space-x-10 my-2">
          <LoginSocialGoogle />
        </div> */}
      </div>
      <div className="space-y-4">
        <div className="text-center text-sm text-muted-foreground">
          <div className="text-sm text-muted-foreground">
            {t("components.loginCard.resetPassword")}{" "}
            <span
              className="cursor-pointer text-sm text-muted-foreground underline"
              onClick={() => setFormState({ view: "resetPassword" })}
            >
              {t("components.loginCard.here")}
            </span>
          </div>
          <div>
            {t("Do not have account? Create ")}{" "}
            {/* <Button
              variant='link'
              className="px-1 text-muted-foreground hover:text-foreground underline"
              onClick={() =>
                setFormState({ view: "create" })
              }
            >
              {"here"}
            </Button> */}
            <a
              href={`/create-new-account`}
              className="underline"
              target="_blank"
            >
              {t("components.loginCard.here")}
            </a>
          </div>
          <div className="text-sm">
            {t("components.loginCard.riskTrading")}{" "}
            <a
              href={`https://antos.io/#/legal-document`}
              className="underline"
              target="_blank"
            >
              {t("notice")}
            </a>
            {/* <span className="cursor-pointer text-xs text-muted-foreground underline">
              <Disclaimer />
            </span> */}
            {/* <span
              className="cursor-pointer select-none underline underline-offset-4 hover:text-primary"
              onClick={() =>
                router.push("https://www.mktdynamics.com/disclaimer")
              }
            >

              {t("components.loginCard.notice")}
            </span> */}
          </div>
        </div>
        <hr className="text-muted-foreground" />
        <div className="text-center text-sm text-muted-foreground">
          <span>{t("components.troubleLogging")}</span>
          <Button
            variant="link"
            className="p-0 h-auto text-muted-foreground hover:text-foreground underline"
            onClick={() => {
              window.location.href =
                "mailto:contact@trylevel2.com?subject=Request%20Assistance&body=Hi%20Team,%0DI%20need%20assistance.%20My%20contact%20email%20is%20{enter%20your%20email%20address}%0D%0D%0D%0D";
            }}
          >
            {t("components.contactMail")}
          </Button>
        </div>
      </div>
    </>
  );
};

// LoginCard.getInitialProps(context) {
//     // Fetch data from external API
//     // const res = await fetch(`https://.../data`)
//     // const data = await res.json()

//     // Pass data to the page via props
//     return { props: { } }
// }

export default LoginCard;
